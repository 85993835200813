<template>
  <vz-dialog
    big
    hide-footer
    ref="dialog"
    class="vz-add-claim-modal vz-request-claim-modal"
    name="vz-request-claim-modal"
  >
    <template #title>
      <vz-icon
        name="arrow-left"
        size="20"
        title="Назад"
        class="vz-cursor-pointer"
        @click="back"
      />
      Претензия
    </template>
    <vz-add-claim-form is-feedback @hide="$hideDialog({ name: 'vz-request-claim-modal' })" />
  </vz-dialog>
</template>
  
<script setup lang="ts">
import VzAddClaimForm from '~/components/forms/contractor/vz-add-claim-form.vue'

const { $showDialog, $hideDialog } = useNuxtApp()

function back() {
  $hideDialog({ name: 'vz-request-claim-modal' })
  $showDialog({ name: 'feedback-message' })
}
</script>